import React from "react";
import useSWR from "swr";
import axios from "axios";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";

const fetcher = (url, id) =>
    axios
        .post(url, {
            id,
        })
        .then(res => res.data);

const Component = ({ params: { id } }) => {
    const { data } = useSWR(["/.netlify/functions/app-modules-list", id], fetcher);

    return (
        <Layout>
            <Seo title={`Aplicación: ${id}`} />

            <section className="bg-gray-50">
                <div className="container py-10 overflow-hidden">
                    <Title>Aplicación {id}</Title>
                    {data && data?.length > 0
                        ? data.map((p, idx) => {
                              return (
                                  <div
                                      key={p.id}
                                      className={`px-4 md:px-0 py-4 border-b flex flex-col md:flex-row gap-4 ${
                                          idx % 2 === 0 ? "bg-gray-100 bg-opacity-50" : "bg-white"
                                      }`}
                                  >
                                      <div className="w-full h-48 md:w-24 md:h-24 rounded-md overflow-hidden flex-shrink-0">
                                          <img
                                              src={`https://igub2.net/cms/resources/images/modules/banner_${p.id}.jpg`}
                                              alt={p.title}
                                              className="object-cover w-full h-full"
                                          />
                                      </div>
                                      <div>
                                          <h1 className="font-semibold font-montserrat text-gray-600">
                                              {p.title} ({p.id} - {p.slug})
                                          </h1>
                                          <ul>
                                              {p.subscriptions
                                                  .filter(s => s.enabled)
                                                  .map(s => {
                                                      return (
                                                          <li
                                                              key={s.slug}
                                                              className="ml-1 font-montserrat text-sm"
                                                          >
                                                              <h1>
                                                                  · {s.title} - {s.slug}:{" "}
                                                                  <span className="text-gray-700 font-medium">
                                                                      {s.price} €
                                                                  </span>
                                                                  {s.offer && (
                                                                      <span className="ml-2 text-red-800 text-xs">
                                                                          {s.start_at} - {s.end_at}
                                                                      </span>
                                                                  )}
                                                                  {!s.refund && (
                                                                      <span className="text-red-700 text-sm ml-2">
                                                                          *
                                                                      </span>
                                                                  )}
                                                                  {/*<span className="text-xs italic ml-5 text-blue-700 font-semibold">Duración: {s.duration} mes/es</span>*/}
                                                              </h1>
                                                          </li>
                                                      );
                                                  })}
                                          </ul>
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </section>
        </Layout>
    );
};

export default Component;
